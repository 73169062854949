import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { CTACard, animateObjects, newContent } from '../../components/Layout';
import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';

import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="Maandumislehtede disain ja arendus"
          description="Ära raiska aega! Disainime ja programmeerime teile tulemusi toovad maandumislehed või kampaaniaveebid. Testime, mõõdame ja optimiseerime, et tõsta müüki."
          image="/pagecover.jpg"
        />
      </GiveContentTrans>

      <GiveContentTrans language="en">
        <SEO
          title="🎯 Landing page design and development"
          description="Landing pages that convert! Our team designs campaign landing pages that capture leads and drive results."
          image="/pagecover-en-default.jpg"
        />
      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Maandumislehe disain ja arendus</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Landing page design and development</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
              Maandumislehed ehk inglise keelses <italic>Landing page</italic>{' '}
              on spetsiaalselt müügile või kliendikontaktide kogumisele mõeldud
              veebilehed. Maandumislehtede sisu ja disain on fokuseeritud kindlale eesmärgile. Oleme disaininud üle saja erineva maandumislehe. 
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              Landing pages, are focused web pages specifically designed for
              sales or collecting customer contacts. We have designed hundreds of
              landing pages. And we are ready to do one or few for you too. 
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">
        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/maandumisleht-hero.jpg"
            alt="Responsive maandumislehe disain"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/maandumisleht-hero.jpg"
            alt="Responsive landing page design"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Maandumislehe disain ja arendus</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Design and Development of Landing Page</h2>
        </GiveContentTrans>

        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Maandumislehe juures on UI/UX
                disainil üli oluline eesmärk edu saavutamisel. Kasutajal kulub 0,05
                sekundit arvamuse loomiseks ja see toimub ainult läbi
                maandumislehe disaini. Hästi konverteerivate maandumislehtede
                kujundamine on maraton mitte sprint.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                As with a regular website, design plays a crucial role in
                achieving success with landing pages. Users take just 0.05
                seconds to form an opinion, and this can only be achieved
                through deliberate design. Designing high-converting landing
                pages is a marathon, not a sprint.
              </p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <StaticImage
              src="../../assets/maandumisleht-2.jpg"
              alt="Maandumislehe disain"
              layout="fullWidth"
            />
          </div>
        </div>

        <div className="content-row has-media">
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/maandumisleht-3.jpg"
                alt="Maandumislehe programmeerimine ja animeerimine"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/maandumisleht-3.jpg"
                alt="Landing page development and animation"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Teeme maandumislehe kujundusest veebibrauseritele arusaadava
                koodi. Vajadusel liidestame API-dega. Animeerime ja muudame teie
                maandumislehe omanäoliseks, et tuua maksimaalselt tulemusi.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                We create web code that is understandable for web browsers from
                landing page designs. If necessary, we integrate with APIs. We
                animate and customize your sales page to bring maximum results.
              </p>
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Maandumislehe konversiooni optimiseerimine ehk CRO&nbsp;teenus</h3>
              <p>
                Parimaid tulemusi ei saavutata üle öö. Nii nagu spordis, tuleb
                heade tulemuste saavutamiseks näha püsivalt vaeva. Oleme Gives
                teinud maandumislehti eri tüüpi klientidele. Kõige rohkem
                kogemust on meil panganduse, kindlustuse, meditsiini ja telekomi
                aladelt.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Landing page conversion rate optimization (CRO)</h3>
              <p>
                The best results are not achieved overnight. Just like in
                sports, consistent effort is required to achieve good results.
                At Gives, we have created landing pages for various types of
                clients, with the most experience in the fields of banking,
                insurance, healthcare, and telecommunications. We are ready to overlook your current funnels and optimize for results.
              </p>
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Maandumislehe AB testimine</h3>
              <p>
                AB testimine tähendab väga lihtsalt seletades seda, et ühest
                maandumislehest näidatakse kasutajatele kahte konkureerivat
                versiooni. Mõlemale versioonile saadetakse samasugune arv
                külastajaid ja seejärel mõõdetakse kumb variant toob paremat
                tulemust. AB testimine on üks lihtsamaid ja täpsemaid võimalusi
                parandada oma digitaalsete kampaaniate tulemuslikust.
              </p>
              <p>
                Maandumislehe AB testimise puhul tasub tähelepanu pöörata sellele, et 
                kindla komponendi muudatuse tähtsust saab hinnata ainult siis kui vaid seda elementi on muudetud. 
                Kui muudatusi on rohkem siis on tegemist split-testiga. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>AB testing</h3>
              <p>
                AB testing simply means that two competing versions of a web
                page are shown to users. An equal number of visitors are sent to
                both versions, and then the results are measured to see which
                version performs better. AB testing is one of the simplest and
                most accurate ways to improve the effectiveness of your digital
                campaigns.
              </p>
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Kodulehtede tegemine
            </CTACard>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Webpage design&development
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>

      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
            <h2>Soovid maandumislehte?</h2>
            <p>
              Müügile suunatud maandumislehtede kasutamine tuleb kasuks igale
              ettevõttele. Paindlikkus, kogemus ja reageerimiskiirus teevad Givest hea
              partneri digitaalsete reklaamikampaaniate ja maandumislehtede kujundamisel ning arendamisel. Võtke ühendust ja arutame kuidas saaksime teie maandumislehed panna tulemusi tooma. 
            </p>
            <p>
              Kui teie ettevõttel on püsivalt vaja maandumislehtede kujundamist ja UI/UX disaini võib teile pakkuda huvi ka meie <a href="/teenused/piiramatult-disaini/">
               piiramatu disaini pakett
          </a>.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>Would you like a landing&nbsp;page?</h2>
            <p>
              The use of sales-oriented landing pages is beneficial for any
              business. Flexibility and responsiveness make Give a great partner
              in creating digital advertising campaigns and product pages.
            </p>

            <p>
            If your company consistently requires landing pages and UI/UX design, you may also be interest of <a href="/services/design-subscription/">unlimited design subscription
          </a>.
          </p>
          </GiveContentTrans>
        </div>
      </section>
    </main>
  
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;